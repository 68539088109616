import React, { useCallback, useEffect, useRef, useState } from "react";
import NotificationDetailComponent from "./notification-detail.component";
import {
  fetchListNotification,
  GET_MARK_ALL_AS_READ,
} from "../../../apis/notification.api";
import NotificationIcon from "../../../assets/notification-icon.svg";
import SettingNotifyIcon from "../../../assets/setting-notify-icon.svg";
import { useClickOutside } from "../../../hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function NotificationComponent({ avatar }) {
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPage: 0,
    unreadCount: 0,
  });
  const [listNotication, setListNotification] = useState([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const dropdownRef = useRef(null);
  const detailRef = useRef(null);
  const listRef = useRef(null);
  const navigate = useNavigate();

  useClickOutside(dropdownRef, () => {
    setIsOpenDropdown(false);
  });

  useClickOutside(detailRef, () => {
    setIsOpenDetail(false);
  });

  const loadMoreData = () => {
    if (!loading && pagination.page < pagination.totalPage) {
      setLoading(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1,
      }));
    }
  };

  const handleToggleNotifyDropdown = () => {
    setIsOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollHeight - scrollTop <= clientHeight + 100) {
        loadMoreData();
      }
    }
  };

  const markAllAsRead = async () => {
    const response = await fetch(GET_MARK_ALL_AS_READ, {
      headers: {
        Authorization: `Bearer ${Cookies.get(
          `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (result.success) {
      setListNotification((prevList) =>
        prevList.map((item) => ({
          ...item,
          markAsRead: true,
        }))
      );
      setPagination({
        ...pagination,
        unreadCount: 0,
      });
    }
    setIsOpenDetail(false);
  };

  useEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
      return () => {
        listElement.removeEventListener("scroll", handleScroll);
      };
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchListNotification({
          page: pagination.page,
          limit: pagination.limit,
        });

        if (data?.data?.length > 0) {
          setListNotification((prevList) =>
            pagination.page === 1 ? data.data : [...prevList, ...data.data]
          );
          setPagination((prevPagination) => ({
            ...prevPagination,
            totalPage: data.totalPage,
            unreadCount: data.unreadCount,
          }));
        }
      } catch (error) {
        console.error("Error loading more data:", error);
      } finally {
        setLoading(false);
      }
    };
    if (loading) {
      fetchData();
    }
  }, [pagination.page, loading]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div className="flex gap-4 justify-center items-center">
      <div
        ref={dropdownRef}
        className={`relative ${
          isOpenDropdown ? "bg-[#A6D38D]" : " bg-white"
        } rounded-full h-10 w-10 flex items-center justify-center cursor-pointer`}
        onClick={(e) => {
          e.stopPropagation();
          handleToggleNotifyDropdown();
        }}
      >
        <img
          className="w-5 h-5 2x:w-6 2xl:h-6"
          src={NotificationIcon}
          alt="notify"
        />
        {pagination.unreadCount > 0 && (
          <div className="absolute bg-red-600 text-white text-[10px] rounded-full h-4 w-4 flex items-center justify-center font-bold right-1 top-1">
            {pagination.unreadCount > 99 ? "99+" : pagination.unreadCount}
          </div>
        )}
        <div
          ref={listRef}
          className={`absolute ${
            isOpenDropdown ? "block" : "hidden"
          } top-14 right-[-10] md:right-0  w-[300px] md:w-[490px] p-5 shadow-md border border-gray-200 rounded-lg overflow-auto max-h-[500px] bg-white`}
        >
          <div
            className="rounded-lg p-2 cursor-default flex justify-between items-center mb-5"
            ref={detailRef}
          >
            <span className="font-extrabold	text-lg text-[#084F4B]">
              Notifications
            </span>
            <img
              src={SettingNotifyIcon}
              alt="settings"
              onClick={(e) => {
                e.stopPropagation();
                if (listNotication.length > 0) {
                  setIsOpenDetail((prev) => !prev);
                } else {
                  navigate("/notification-settings");
                }
              }}
              className="cursor-pointer"
            />
            {isOpenDetail && (
              <div className="absolute right-0 top-16 w-40 p-2 rounded-lg shadow-md border border-gray-200 z-50 bg-white flex flex-col gap-2">
                <div
                  className="py-2 px-3 hover:bg-[#A6D38D] rounded-md transition-all cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    markAllAsRead();
                  }}
                >
                  <span className="text-sm font-normal">Mark All as Read</span>
                </div>
                <div
                  className="py-2 px-3 hover:bg-[#A6D38D] rounded-md transition-all cursor-pointer"
                  onClick={() => navigate("/notification-settings")}
                >
                  <span className="text-sm font-normal">Settings</span>
                </div>
              </div>
            )}
          </div>
          {listNotication?.map((elm, index) => (
            <NotificationDetailComponent
              key={index}
              avatar={avatar}
              elm={elm}
              index={index}
              setListNotification={setListNotification}
              setPagination={setPagination}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotificationComponent;
